import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    TextField,
    Paper,
    Switch,
    FormControlLabel,
    makeStyles,
    createMuiTheme,
    MuiThemeProvider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import { React, useState, useEffect, useContext } from 'react';
import useData from './useData';
import theme from '../../Theme';
import GlobalState from '../../GlobalState';

function AdviserForm() {

    const useStyles = makeStyles({
        card: {
            backgroundColor: '#263238',

        },
        cardHeader: {
            backgroundColor: 'rgb(24,77,163)',

        },
        cardButton: {
            padding: '8px',
            position: 'relative',
            top: '4px',
            color: 'rgba(255,255,255)'

        },
        paper: {
            borderRadius: 10,
        },

        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }

    });

    const { getData } = useData();

    const [globalData, updateGlobalData] = useContext(GlobalState);

    const [toggle, updateToggle] = useState(false);
    const [fieldErrors, updateFieldErrors] = useState({
        name: false,
        lastName: false,
        email: false,
        chapterField: false,
        chapter: false,
        server: false,
        success: false,
        message: '',
        chapterCode: false,
        chapterMessage: 'This field cannot be empty.',
        type: false
    });
    const [values, setValues] = useState({
        name: '',
        lastName: '',
        email: '',
        chapter: [],
        chapterField: '',
        chapterCode: '',
        type: ''

    });
    const handleChange = (e) => {
        updateToggle(!toggle)

    }

    useEffect(() => {
        getData();
    }, []);

    const handleReset = (e) => {
        e.preventDefault();
        setValues({
            chapter: [],
            name: '',
            lastName: '',
            email: '',
            chapterField: '',
            chapterCode: ''
        });
        updateFieldErrors({
            success: false,
            server: false
        });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();


        if ((fieldErrors.chapter || fieldErrors.email || fieldErrors.name || fieldErrors.chapterField || fieldErrors.chapterCode)) {
            updateFieldErrors({
                chapter: true,
                email: true,
                name: true
            });

            return 0;

        }

        if (values.type === null) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                type: true,
            }));
            return 0;
        }

        if (isNaN(values.chapterCode)) {

            updateFieldErrors(
                fieldErrors => ({
                    ...fieldErrors,
                    chapterCode: true,
                    chapterMessage: 'Must be a numeric value'
                })
            )


            return 0;

        }

        try {

            if (toggle) {

                const res = await fetch('/create/chapter', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: values.chapterField,
                        code: values.chapterCode,
                        type: values.type
                    })
                });
                const data = await res.json();

                if (data.error != null) {
                    var errorMessage = data.error + " This user and chapter were not created.";
                    updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: errorMessage }))
                    return 0;
                };


                const chapterID = [data.chapterID];

                console.log(chapterID)

                const userRes = await fetch('/create/adviser', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: values.name,
                        lastName: values.lastName,
                        email: values.email,
                        password: Math.random().toString(36).slice(2),
                        chapter: chapterID

                    })
                });


                const userData = await userRes.json();

                if (userData.error != null) {

                    const deleteChapter = await fetch('/data/deleteChapter', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({

                            chapter: chapterID,

                        })
                    });

                    const errorMessage = userData.error + " The chapter and user were not created."

                    updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: errorMessage }));

                    return 0;
                };


                updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: false, success: true, message: "Successfully saved! Click the reset button below to clear the fields and add another adviser." }));
            }

            else {

                if (values.chapter.length === 0) {
                    updateFieldErrors({ chapter: true });
                    return 0;
                }
                else if (values.name === '') {
                    updateFieldErrors({ name: true });
                    return 0;
                }
                else if (values.email === '') {
                    updateFieldErrors({ email: true });
                    return 0;
                }

                var currentChapterIds = [];

                values.chapter.forEach(element => {
                    currentChapterIds = [...currentChapterIds, element.id];
                });
                console.log(currentChapterIds)
                const userRes = await fetch('/create/adviser', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: values.name,
                        lastName: values.lastName,
                        email: values.email,
                        password: Math.random().toString(36).slice(2),
                        chapter: currentChapterIds

                    })
                });

                const data = await userRes.json();

                if (data.error != null) {
                    updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: data.error }));
                    return 0;
                }
                else {

                    updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: false, success: true, message: "Successfully saved! Click the reset button below to clear the fields and add another adviser." }));
                }
            }

            getData();

        }
        catch (e) {
            console.log(e);
        }

    };

    const handleValueChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));

        if (!(e.target.value))
            updateFieldErrors(values => ({
                ...fieldErrors,
                [e.target.name]: true
            }));
        else
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: false
            }));


    };

    const onTagsChange = (event, value) => {
        setValues(values => ({ ...values, chapter: value }));
        if (value.length == 0)
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, chapter: true }));
        else
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, chapter: false }));
    };
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={theme}>
            <form>
                <Card elevation={5} className={classes.paper}>
                    <CardHeader disableTypography={true} className={classes.cardHeader}
                        action={<FormControlLabel className={classes.cardButton}
                            control={<Switch checked={toggle}
                                color='secondary'
                                onChange={handleChange} />}
                            labelPlacement='start'
                            label={toggle ? "New Chapter" : "Existing Chapter"} />}

                        title={<Typography variant='h5'>Create Adviser Account</Typography>} />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2} justify="center">
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    error={fieldErrors.name}
                                    helperText={fieldErrors.name && "This field cannot be empty."}
                                    onChange={handleValueChange}
                                    value={values.name}
                                    label="First Name"
                                    name="name"
                                    required variant="outlined" />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField

                                    fullWidth
                                    error={fieldErrors.lastName}
                                    helperText={fieldErrors.lastName && "This field cannot be empty. "}
                                    onChange={handleValueChange}
                                    value={values.lastName}
                                    label="Last Name"
                                    name="lastName"
                                    required
                                    variant="outlined"

                                />

                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    value={values.email}
                                    error={fieldErrors.email}
                                    helperText={fieldErrors.email && "This field cannot be empty."}
                                    onChange={handleValueChange}
                                    label="Email"
                                    name="email"
                                    required
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Autocomplete
                                    value={values.type}
                                    options={['Middle Level', 'High School']}
                                    fullWidth
                                    required
                                    onChange={(event, value) => setValues(values => ({ ...values, type: value }))}
                                    renderInput={(params) =>
                                        <TextField {...params} error={fieldErrors.type} helperText={fieldErrors.type && "This field cannot be empty."} label="Chapter Level" variant="outlined" />}
                                />

                            </Grid>
                            {!toggle ?
                                <Grid item xs md={12} xs={12}>
                                    <Autocomplete
                                        key={fieldErrors.success}
                                        fullWidth
                                        value={values.chapter}
                                        onChange={onTagsChange}
                                        multiple
                                        options={globalData.chapters}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => <TextField {...params} error={fieldErrors.chapter}
                                            helperText={fieldErrors.chapter && "This field cannot be empty."} name="chapter" label="Choose Chapter(s)" variant="outlined" />}
                                    />
                                </Grid> :
                                <Grid container item spacing={2} direction='rows'>

                                    <Grid item md={6} xs={12} >


                                        <TextField
                                            value={values.chapterField}
                                            onChange={handleValueChange}
                                            error={fieldErrors.chapterField}
                                            helperText={fieldErrors.chapterField && "This field cannot be empty."}
                                            fullWidth
                                            label="Exact Chapter Name"
                                            name="chapterField"
                                            required
                                            variant="outlined"
                                        />

                                    </Grid>
                                    <Grid item md={6} xs={12}>

                                        <TextField
                                            value={values.chapterCode}
                                            onChange={handleValueChange}
                                            helperText={fieldErrors.chapterCode && fieldErrors.chapterMessage}
                                            fullWidth
                                            error={fieldErrors.chapterCode}
                                            label="Chapter Code"
                                            name="chapterCode"
                                            required
                                            variant="outlined"
                                        />

                                    </Grid>

                                </Grid>

                            }
                        </Grid>
                    </CardContent>
                    <Divider />
                    {fieldErrors.server && <p className={classes.message}>{fieldErrors.message}</p>}
                    {fieldErrors.success && <p className={classes.message1}>{fieldErrors.message}</p>}
                    <CardActions style={{ justifyContent: 'center' }}>
                        {fieldErrors.success ? <Button size="medium" onClick={handleReset}>RESET</Button> :
                            <Button size="medium" onClick={handleSubmit}>SUBMIT</Button>}
                    </CardActions>
                </Card>
            </form>
        </MuiThemeProvider>
    )
}

export default AdviserForm
