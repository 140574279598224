import { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import GlobalState from '../../GlobalState'

const useForm = () => {

    const location = useLocation();

    const history = useHistory();

    const [globalData, updateGlobalData] = useContext(GlobalState);

    const [values, setValues] = useState({

        email: '',
        password: '',

    })

    const [errors, setErrors] = useState({
        message: ''
    })


    const pages = {

        member: ['/members'],
        admin: ['/advisers', '/create',],
        super_admin: ['/admin/chapters', '/admin/create', '/admin/records', '/admin/reports']

    }


    const handleChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch('/auth/login', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: values.email,
                    password: values.password
                })
            });

            const data = await res.json()

            if (data.error === null) {

                if (data.type === 'admin') {
                    updateGlobalData(globalData => ({
                        ...globalData,
                        isAuth: true,
                        name: data.name,
                        userID: data.userID,
                        type: data.type,
                        chapterID: data.chapters,
                        email: data.email,
                        chapterString: data.chapterString,
                        isAdmin: true,
                    }));
                }
                else if (data.type === 'member') {
                    updateGlobalData(globalData => ({
                        ...globalData,
                        isAuth: true,
                        name: data.name,
                        userID: data.userID,
                        type: data.type,
                        chapterID: data.chapters,
                        email: data.email,
                        chapterString: data.chapterString,
                        isMember: true,
                    }));
                }
                else if (data.type === 'super-admin') {
                    updateGlobalData(globalData => ({
                        ...globalData,
                        isAuth: true,
                        name: data.name,
                        userID: data.userID,
                        type: data.type,
                        chapterID: data.chapters,
                        email: data.email,
                        chapterString: data.chapterString,
                        isSuperAdmin: true,
                    }))
                }


                if (data.type === 'admin') {

                    if (pages.admin.includes(location.pathname))
                        history.push(location.pathname, { from: 'Home' });
                    else
                        history.push('/create', { from: 'Home' });
                }

                else if (data.type === 'member') {

                    if (pages.member.includes(location.pathname))
                        history.push(location.pathname, { from: 'Home' });
                    else
                        history.push('/members', { from: 'Home' });
                }

                else if (data.type === 'super-admin') {

                    if (pages.super_admin.includes(location.pathname))
                        history.push(location.pathname, { from: 'Home' });
                    else
                        history.push('/admin/chapters', { from: 'Home' });
                }


            }

            else {
                updateGlobalData(globalData => ({
                    ...globalData,
                    name: '',
                    userID: '',
                    type: '',
                    chapterID: [],
                    isAuth: false,
                    email: '',
                    chapterString: []
                }));

                setErrors({ message: data.error })

            }


            //history.push(location.pathname, { from: 'Home' });

        }
        catch (e) {
            console.log(e)
        }
    }

    return { handleChange, values, handleSubmit, errors };

}

export default useForm;

