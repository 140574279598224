import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import { Route, Redirect, useHistory, Switch } from 'react-router-dom'
import Home from './components/HomePage/Home';
import GlobalState from './GlobalState.js';
import Chapters from './components/ChapterPage/Chapters';
import Member from './components/Members/Member';
import AdviserHome from './components/Advisers/AdviserHome';
import CreateMembers from './components/Advisers/CreateMembers';
import Logout from './Logout';
import AdminMember from './components/Admin/AdminMembersPage';
import AdminRecordsTable from './components/Admin/AdminRecordsTable';
import AdminHome from './components/Admin/AdminHome';
import Misc from './components/Admin/Misc';
import Password from './Password';


function App() {

  //CHANGE IMPLEMENTATION FOR SESSION STORAGE here and useForm at login
  const [globalData, updateGlobalData] = useState({
    chapters: [],
    advisers: [],
    isAuth: false,
  });

  const verifyUser = async () => {

    const res = await fetch('/auth/tokenCheck');

    try {

      const data = await res.json();

      if (data.error === null) {

        if (data.type === 'admin') {
          updateGlobalData(globalData => ({
            ...globalData,
            isAuth: true,
            lastName: data.lastName,
            name: data.name,
            userID: data.userID,
            type: data.type,
            chapterID: data.chapters,
            email: data.email,
            chapterString: data.chapterString,
            isAdmin: true,
          }));
        }
        else if (data.type === 'member') {
          updateGlobalData(globalData => ({
            ...globalData,
            isAuth: true,
            lastName: data.lastName,
            name: data.name,
            userID: data.userID,
            type: data.type,
            chapterID: data.chapters,
            email: data.email,
            chapterString: data.chapterString,
            isMember: true,
          }));
        }
        else if (data.type === 'super-admin') {
          updateGlobalData(globalData => ({
            ...globalData,
            isAuth: true,
            name: data.name,
            lastName: data.lastName,
            userID: data.userID,
            type: data.type,
            chapterID: data.chapters,
            email: data.email,
            chapterString: data.chapterString,
            isSuperAdmin: true,
          }))
        }

      }
      else {
        updateGlobalData(globalData => ({
          ...globalData,
          name: '',
          userID: '',
          type: '',
          chapterID: [],
          isAuth: false,
          email: '',
          chapterString: []
        }));
      }
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    verifyUser()
  }, [])

  const location = useHistory();
  return (
    <GlobalState.Provider value={[globalData, updateGlobalData]}>

      <Route path='/' exact component={Home} />
      <Route path='/admin/chapters' exact component={globalData.isSuperAdmin ? Chapters : Home} />
      <Route path='/members' exact component={globalData.isMember ? Member : Home} />
      <Route path='/advisers' exact component={globalData.isAdmin ? AdviserHome : Home} />
      <Route path='/create' exact component={globalData.isAdmin ? CreateMembers : Home} />
      <Route path='/logout' exact component={Logout} />
      <Route path='/admin/create' exact component={globalData.isSuperAdmin ? AdminMember : Home} />
      <Route path='/admin/records' exact component={globalData.isSuperAdmin ? AdminHome : Home} />
      <Route path='/admin/reports' exact component={globalData.isSuperAdmin ? Misc : Home} />
      <Route path='/password' exact component={Password} />
    </GlobalState.Provider>
  );
}

export default App;
