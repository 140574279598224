import { React, useEffect, useContext, useState } from 'react';
import Navigation from '../Navbar/Navigation';
import {
    Grid,
    MuiThemeProvider,
    makeStyles,
    TextField,
    Card,
    CardContent,
    CardActions,
    Button,
    Divider
} from '@material-ui/core';
import theme from '../../Theme';
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GlobalState from '../../GlobalState';
import { CircularProgress } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import AdviserRecordsTable from './AdviserRecordsTable';

function AdviserHome() {

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            background: '#dad6df',

        }
    }));

    const [globalData, setGlobalData] = useContext(GlobalState);

    const [values, setValues] = useState({
        name: '',
        email: '',
        approved: '',
        bool: null
    });

    const [filters, setFilters] = useState({
        name: '',
        email: '',
        approved: null

    });


    useEffect(() => {
    }, []);

    const handleChange = (e, value) => {
        setValues(values => ({
            ...values,
            name: value
        }));
    };

    const handleChange1 = (e, value) => {
        setValues(values => ({
            ...values,
            email: value
        }));
    };

    const handleChange2 = (e, value) => {
        if (value === 'True')
            setValues(values => ({
                ...values,
                bool: true,
                approved: value
            }));
        else if (value === 'False')
            setValues(values => ({
                ...values,
                bool: false,
                approved: value
            }));
        else
            setValues(values => ({
                ...values,
                bool: null,
                approved: value
            }));

    };

    const updateProps = () => {

        setFilters({
            name: values.name,
            email: values.email,
            approved: values.bool
        });
    };


    const classes = useStyles();

    //removed filtering props functionality, decided to use built in MUI Table filtering

    return (
        <MuiThemeProvider theme={theme}>
            <Navigation buttons={[
                {
                    name: 'Members',
                    link: '/create'
                },
                {
                    name: 'Log Out',
                    link: '/logout'
                }
            ]} ></Navigation>
            <div className={classes.root}>
                <Grid container spacing={2} justify='center' alignContent='center' style={{ minHeight: '90vh' }}>
                    <Grid item md={12}>
                        <AdviserRecordsTable name={filters.name} approved={filters.approved} email={filters.email} />
                    </Grid>
                </Grid>
            </div>
        </MuiThemeProvider>
    )
}

export default AdviserHome
