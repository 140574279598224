import { React, useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import GlobalState from '../../GlobalState';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    MuiThemeProvider,
    makeStyles,
    Paper,
    TextField
} from '@material-ui/core';
import theme from '../../Theme';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { CircularProgress } from '@material-ui/core';
function AdviserRecordsTable(props) {
    const useStyles = makeStyles({
        myEditIcon: {
            color: "rgb(24,77,163)",
        },
        myTrashIcon: {
            color: 'rgb(227,44,44)'
        },
        myDownloadIcon: {
            color: '#4CBB17'
        },
        paper: {
            backgroundColor: '#222222'
        }
    });

    const classes = useStyles();

    const [globalData, setGlobalData] = useContext(GlobalState);

    const [data, setData] = useState([]);

    const [load, setLoad] = useState(false);

    const getRecords = async () => {
        var currentData = []

        setLoad(false)

        try {
            const res = await fetch('/data/filteredRecords', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: props.email,
                    name: props.name,
                    approved: props.approved,
                    chapters: globalData.chapterID
                })
            });

            const resData = await res.json();
            var currentData = [];
            resData.records.forEach(item => {

                const entry = {
                    hours: Math.floor(item.timeValue / 60),
                    minutes: (item.timeValue % 60),
                    approved: item.approved,
                    benefactor: item.benefactor,
                    description: item.description,
                    recordID: item._id,
                    lastName: item.lastName,
                    name: item.name,
                    dateCreated: item.dateCreated,
                    email: item.email,
                    approvedBy: item.approvedByString,
                    approvedDate: item.approvedDate

                }

                currentData = [...currentData, entry]

            });
            setData(currentData);
            setLoad(true)
            console.log(currentData)

        }
        catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        getRecords();

    }, [props.name, props.email, props.approved]);



    return (
        <MuiThemeProvider theme={theme}>
            {!load ? <Skeleton variant="rect" width={210} height={118} /> :
                <MaterialTable
                    style={{ backgroundColor: '#222222' }}
                    components={{
                        Container: props => <Paper className={classes.paper} {...props} elevation={5} />,

                        Toolbar: props => (
                            <div style={{ backgroundColor: 'rgb(24,77,163)', color: 'rgba(255,255,255,.87)' }}>
                                <MTableToolbar {...props} />
                            </div>
                        )
                    }}

                    title="Chapter Submissions"
                    icons={{
                        Edit: () => <EditIcon className={classes.myEditIcon} />,
                        Delete: () => <DeleteOutlineIcon className={classes.myTrashIcon} />,
                        Export: () => <CloudDownloadIcon className={classes.myDownloadIcon} />
                    }}
                    columns={[
                        { title: 'Name', field: 'name', type: 'string', editable: false },
                        { title: 'Last Name', field: 'lastName', type: 'string', editable: false },
                        { title: 'Email', field: 'email', type: 'string', editable: false },
                        { title: 'Hours', field: 'hours', type: "numeric", },
                        { title: 'Minutes', field: 'minutes', type: "numeric", },
                        { title: 'Benefactor', field: 'benefactor', type: "string", },
                        { title: 'Date Created', field: 'dateCreated', type: 'date', editable: false },
                        {
                            title: 'Approved', field: 'approved', editable: 'false', type: "boolean", cellStyle: (e, rowData) => {
                                if (!rowData.approved) {
                                    return { color: "red" };
                                } else return { color: "green" }
                            }
                        },
                        { title: 'Approve By', field: 'approvedBy', type: 'string', editable: false },
                        { title: 'Approval Date', field: 'approvedDate', type: 'date', editable: false },
                        {
                            title: 'Description', field: 'description', type: 'string', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 150 },
                            editComponent: props => (

                                <TextField
                                    multiline
                                    rowsMax={4}
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    variant="filled"
                                />
                            )

                        }]}
                    data={data}
                    options={{
                        filtering: true,
                        search: true,
                        exportButton: true,
                        exportAllData: true,
                        actionsColumnIndex: -1,
                        pageSize: 7,
                        headerStyle: { backgroundColor: '#22222' },
                        selection: true

                    }}

                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Table',
                            isFreeAction: true,
                            onClick: (event) => getRecords()
                        },
                        {
                            icon: () => <ThumbUpIcon />,
                            tooltip: 'Approve all selected rows',
                            onClick: async (evt, data) => {

                                for (var x = 0; x < data.length; x++) {
                                    var element = data[x];
                                    if (element.approved) {
                                        console.log('hello1')
                                        element.approved = true;
                                        element.changed = false
                                    }
                                    else {
                                        console.log('hello')
                                        element.approved = true;
                                        element.changed = true;
                                    }
                                }

                                console.log(data)

                                try {
                                    const res = await fetch("/data/massRecordChange", {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            data: data,
                                            userID: globalData.userID,
                                            name: globalData.name,
                                        })
                                    });

                                    getRecords();
                                }

                                catch (error) {
                                    console.log(error)
                                }

                            }
                        },
                        {
                            icon: () => <ThumbDownIcon />,
                            tooltip: 'Reject all selected rows',
                            onClick: async (evt, data) => {


                                for (var x = 0; x < data.length; x++) {

                                    var element = data[x]

                                    if (!element.approved) {
                                        element.approved = false;
                                        element.changed = false
                                    }
                                    else {
                                        element.approved = false
                                        element.changed = true
                                    }

                                }

                                console.log(data)

                                try {
                                    const res = await fetch("/data/massRecordChange", {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            data: data,
                                        })
                                    });

                                    getRecords();
                                }

                                catch (error) {
                                    console.log(error)
                                }

                            }
                        }
                    ]}

                    editable={{
                        isEditable: rowData => !rowData.approved,
                        isDeletable: rowData => !rowData.approved,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setData([...dataUpdate]);
                                    try {
                                        const res = await fetch("/data/approveRecord", {
                                            method: 'post',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                recordID: newData.recordID,
                                                timeValue: (newData.hours * 60) + (newData.minutes),
                                                benefactor: newData.benefactor,
                                                description: newData.description,
                                                approved: newData.approved,
                                                userID: globalData.userID,
                                                name: globalData.name,

                                            })
                                        });
                                    }

                                    catch (error) {
                                        console.log(error)
                                    }
                                    getRecords();
                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {

                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setData([...dataDelete]);

                                    try {

                                        //TODO IMPLEMENT A POPUP THAT PREVENTS FURHTER ACTION
                                        const res = await fetch("/data/deleteRecord", {
                                            method: 'post',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                record: oldData.recordID,

                                            })
                                        });
                                    }

                                    catch (error) {

                                    }

                                    resolve();
                                }, 1000);
                            })

                    }}
                />

            }

        </MuiThemeProvider >
    )
}

export default AdviserRecordsTable
