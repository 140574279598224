import { React, useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProvider, Paper, TextField } from '@material-ui/core';
import theme from '../../Theme';
import GlobalState from '../../GlobalState';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';

function MemberTable() {

    const useStyles = makeStyles({
        myEditIcon: {
            color: "rgb(24,77,163)",
        },
        myTrashIcon: {
            color: 'rgb(227,44,44)'
        },
        myDownloadIcon: {
            color: '#4CBB17'
        },
        paper: {
            backgroundColor: '#222222'
        }
    });

    const classes = useStyles();

    const [userData, setUserData] = useState();

    const [globalData, setGlobalData] = useContext(GlobalState);

    const getRecords = async () => {
        const res = await fetch('/data/records', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: globalData.userID
            })
        });
        const resData = await res.json();
        var currenData = [];
        resData.records.forEach(item => {
            const entry = {
                hours: Math.floor(item.timeValue / 60),
                minutes: (item.timeValue % 60),
                approved: item.approved,
                benefactor: item.benefactor,
                description: item.description,
                dateCreated: item.dateCreated,
                recordID: item._id
            }

            currenData = [...currenData, entry];
        });
        setUserData(currenData);
        console.log(resData.percentage)

        var monthlyData = []

        for (var key in resData.monthlyData) {

            monthlyData = [...monthlyData, Math.floor(resData.monthlyData[key] / 60)]

        }

        var allMonthlyData = []

        for (var key in resData.allMonthlyData) {
            allMonthlyData = [...allMonthlyData, Math.floor(resData.allMonthlyData[key] / 60)]
        }

        console.log(monthlyData)
        console.log(allMonthlyData)

        setGlobalData(globalData => ({
            ...globalData,
            approvedTime: resData.approvedTime,
            unApprovedTime: resData.unApprovedTime,
            percentage: resData.percentage,
            level: resData.level,
            monthlyData: monthlyData,
            allMonthlyData: allMonthlyData,
            dataLoaded: true,
            summary: resData.summary
            
        }));

    };

    useEffect(() => {
        getRecords();
    }, []);

    return (

        <MuiThemeProvider theme={theme}>
            <MaterialTable
                style={{ backgroundColor: '#222222' }}
                components={{
                    Container: props => <Paper className={classes.paper} {...props} elevation={5} />,

                    Toolbar: props => (
                        <div style={{ backgroundColor: 'rgb(24,77,163)', color: 'rgba(255,255,255,.87)' }}>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}

                title="Your Submissions"
                icons={{
                    Edit: () => <EditIcon className={classes.myEditIcon} />,
                    Delete: () => <DeleteOutlineIcon className={classes.myTrashIcon} />,
                    Export: () => <CloudDownloadIcon className={classes.myDownloadIcon} />
                }}
                columns={[
                    { title: 'Hours', field: 'hours', type: "numeric", },
                    { title: 'Minutes', field: 'minutes', type: "numeric", },
                    { title: 'Benefactor', field: 'benefactor', type: "string", },
                    {
                        title: 'Approved', editable: false, field: 'approved', type: "boolean", cellStyle: (e, rowData) => {
                            if (!rowData.approved) {

                                return { color: "red" };
                            } else return { color: "green" }
                        }
                    },
                    {
                        title: 'Description', field: 'description', type: 'string', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 150 },
                        editComponent: props => (

                            <TextField
                                multiline
                                rowsMax={4}
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                                variant="filled"
                            />
                        )

                    },
                    { title: 'Date Created', field: 'dateCreated', type: 'date', editable: false },
                ]}
                data={userData}
                options={{
                    search: true,
                    exportButton: true,
                    exportAllData: true,
                    actionsColumnIndex: -1,
                    pageSize: 5,
                    headerStyle: { backgroundColor: '#22222', padding: '10px' },

                }}

                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Table',
                        isFreeAction: true,
                        onClick: (event) => getRecords()
                    }
                ]}

                editable={{
                    isEditable: rowData => !rowData.approved,
                    isDeletable: rowData => !rowData.approved,
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                const dataUpdate = [...userData];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setUserData([...dataUpdate]);
                                try {
                                    const res = await fetch("/data/updateRecord", {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            recordID: newData.recordID,
                                            timeValue: (newData.hours * 60) + (newData.minutes),
                                            benefactor: newData.benefactor,
                                            description: newData.description,
                                        })
                                    });
                                }

                                catch (error) {
                                    console.log(error)
                                }
                                getRecords();
                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {

                                const dataDelete = [...userData];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setUserData([...dataDelete]);

                                try {

                                    //TODO IMPLEMENT A POPUP THAT PREVENTS FURHTER ACTION
                                    const res = await fetch("/data/deleteRecord", {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            record: oldData.recordID,

                                        })
                                    });
                                }

                                catch (error) {

                                }

                                resolve();
                            }, 1000);
                        })

                }}
            />



        </MuiThemeProvider >
    )
}

export default MemberTable
