import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'

function Logout() {

    useEffect(() => {

        logoff();

    }, []);


    const logoff = async () => {

        const res = await fetch('/auth/logout');

        const resData = await res.json();

    };

    return (
        <div>
            <Grid container xs={12} md={12} justify='center' alignItems='center' style={{ minWidth: '100vh', minHeight: '100vh' }}>
                <h1>Congrats you have succesfully been logged out! <br/> Thank you for visiting the Georgia FBLA CSA system.</h1>
            </Grid>
        </div>
    )
}

export default Logout
