import { React } from 'react'
import useForm from './useForm'
import './Home.css'
import image from '../../images/logo.png'


function Home() {

    const { handleChange, values, handleSubmit, errors } = useForm();

    return (
        <section className="login">
            <div className="loginContainer">
                <div className="header">
                    <h1 className='title'>Georgia FBLA</h1> <img alt='gaFBLAlogo' src={image} />
                    <h1 className='subtitle'>Community Service Award Login</h1>
                    {errors.message && <p className='error'>{errors.message}</p>}
                </div>
                <form method='POST' className='form' onSubmit={handleSubmit}>
                    <label>Email</label>
                    <input
                        type="text"
                        name='email'
                        required
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange} />
                    <label>Password</label>
                    <input
                        name='password'
                        type="password"
                        required
                        value={values.password}
                        placeholder="Password"
                        onChange={handleChange} />
                    <div className="buttoncontainer">
                        <button>Login</button>
                        <span className='form-input-login'>Forget your password? Click <a href='/password'>here</a> to reset it.</span>
                    </div>
                </form>
            </div>

        </section>
    )
}

export default Home
