import { Grid } from '@material-ui/core';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import theme from '../../Theme';
import Navigation from '../Navbar/Navigation';
import AdviserForm from './AdviserForm';
import ChapterForm from './ChapterForm';
import EditAdviserForm from './EditAdviserForm';
import Table from './Table';

function Chapters() {

    //fix navbar viewport (check bottom of page for thin line)


    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            background: '#dad6df'
        }
    }));
    const classes = useStyles();
    return (
        <MuiThemeProvider theme={theme}>
            <Navigation buttons={[{
                name: 'Records',
                link: '/admin/records'
            },
            {
                name: 'Members',
                link: '/admin/create'
            },
            {
                name: 'Reports',
                link: '/admin/reports'
            },
            {
                name: 'Log Out',
                link: '/logout'
            }]} />
            <div className={classes.root}>

                <Grid container spacing={3}>
                    <Grid item md={8} xs={12} >
                        <Table />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item md={12} xs={12} >
                                <AdviserForm />
                            </Grid>
                            <Grid item md={12} xs={12} >
                                <EditAdviserForm />
                            </Grid>
                            <Grid item md={12} xs={12} >
                                <ChapterForm />
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </div>
        </MuiThemeProvider>

    )
}

export default Chapters
