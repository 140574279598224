import { React, useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProvider, createMuiTheme, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import theme from '../../Theme';

function Table() {


    const useStyles = makeStyles({
        myEditIcon: {
            color: "rgb(24,77,163)",
        },
        myTrashIcon: {
            color: 'rgb(227,44,44)'
        },
        myDownloadIcon: {
            color: '#4CBB17'
        },
        paper: {
            backgroundColor: '#222222'
        }
    });

    const classes = useStyles();

    const [data, setData] = useState([]);
    const getAdvisers = async () => {
        var currentData = [];
        try {
            const res = await fetch('/data/advisers');
            const resData = await res.json();
            const array = resData.advisers;
            array.forEach(item => {
                var chapterIDs = item.chapterString.join(', ');
                const newEntry = {
                    name: item.name,
                    lastName: item.lastName,
                    email: item.email,
                    school: chapterIDs,
                    activeSince: item.dateCreated,
                    activeTo: item.dateUncreated,
                    isActive: item.isActive
                };
                currentData = [...currentData, newEntry];
            });
            setData(currentData);
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAdvisers();
    }, []);


    return (

        <MuiThemeProvider theme={theme}>

            <MaterialTable
                style={{ backgroundColor: '#222222' }}
                components={{
                    Container: props => <Paper className={classes.paper} {...props} elevation={5} />,

                    Toolbar: props => (
                        <div style={{ backgroundColor: 'rgb(24,77,163)', color: 'rgba(255,255,255,.87)' }}>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}

                title="Advisers"
                icons={{
                    Edit: () => <EditIcon className={classes.myEditIcon} />,
                    Delete: () => <DeleteOutlineIcon className={classes.myTrashIcon} />,
                    Export: () => <CloudDownloadIcon className={classes.myDownloadIcon} />
                }}
                columns={[
                    { title: 'Name', field: 'name', type: "string", },
                    { title: 'Last Name', field: 'lastName', type: "string", },
                    { title: 'Email', field: 'email', type: "string", },
                    { title: 'School', field: 'school', type: "string", editable: false },
                    {
                        title: 'Active',  field: 'isActive', type: "boolean", cellStyle: (e, rowData) => {
                            if (!rowData.isActive) {

                                return { color: "red" };
                            } else return { color: "green" }
                        }
                    },
                    { title: 'Active From', field: 'activeSince', defaultSort: 'asc' ,  type: 'date', editable: false, },
                    { title: 'Active To', field: 'activeTo', type: 'date', editable: false, },

                ]}
                data={data}
                options={{
                    search: true,
                    exportButton: true,
                    exportAllData: true,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: { backgroundColor: '#22222' },

                }}

                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Table',
                        isFreeAction: true,
                        onClick: (event) => getAdvisers()
                    }
                ]}

                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                console.log(newData.isActive);
                                try {
                                    const res = await fetch("/data/updatePerson", {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            oldEmail: oldData.email,
                                            newEmail: newData.email,
                                            newName: newData.name,
                                            newLastName: newData.lastName,
                                            newisActive: newData.isActive
                                        })
                                    });
                                }

                                catch (error) {

                                }
                                getAdvisers();
                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {

                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);

                                try {

                                    //TODO IMPLEMENT A POPUP THAT PREVENTS FURHTER ACTION
                                    const res = await fetch("/data/deletePerson", {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            email: oldData.email,

                                        })
                                    });
                                }

                                catch (error) {

                                }

                                resolve();
                            }, 1000);
                        })

                }}
            />

        </MuiThemeProvider>
    )
}

export default Table
