import { React, useEffect, useContext, useState } from 'react';
import Navigation from '../Navbar/Navigation';
import {
    Grid,
    MuiThemeProvider,
    makeStyles,
    TextField,
    Card,
    CardContent,
    CardActions,
    Button,
    Divider,
    CardHeader
} from '@material-ui/core';
import theme from '../../Theme';
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GlobalState from '../../GlobalState';
import { CircularProgress } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import AdminRecordsTable from './AdminRecordsTable';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ReportsTable from './ReportsTable';

function Misc() {


    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            background: '#dad6df',
            minHeight: '92vh',

        }

    }));


    const [globalData, setGlobalData] = useContext(GlobalState);

    const [state, setState] = useState({

        beginDate: '01/01/2021',
        finishDate: '01/02/2021'


    });

    const [fieldErrors, setFieldErrors] = useState({

        firstDate: true,
        finishDate: true,
        beginMessage: '',
        endMessage: '',
        success: false,
        server: false,
        message: '',


    });



    const handleDateChange = (date) => {

        setState(state => ({ ...state, beginDate: date }))
        setFieldErrors(fieldErrors => ({ ...fieldErrors, firstDate: false }))

    };

    const handleDateChange2 = (date) => {
        setState(state => ({ ...state, finishDate: date }))
        setFieldErrors(fieldErrors => ({ ...fieldErrors, finishDate: false }))
    }

    const handleSubmit = async (e) => {

        if (fieldErrors.firstDate || fieldErrors.finishDate) {
            setFieldErrors(fieldErrors => ({ ...fieldErrors, message: 'Please select dates', firstDate: true, finishDate: true }))
            return 0;
        }

        console.log(state.beginDate)

        const res = await fetch('/data/reports', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                beginDate: state.beginDate,
                finishDate: state.finishDate,
            })
        });


        const resData = await res.json();

        console.log(resData.awards)

        if (resData.error != null) {

            console.log('helloooo')
            setFieldErrors(fieldErrors => ({
                ...fieldErrors,
                server: true,
                message: 'There was an internal error. Please make sure all dates are appropriate, and retry.'
            }));
        }

        else {

            console.log('hghgjhg')
            setFieldErrors(fieldErrors => ({
                ...fieldErrors,
                success: true,
                message: 'Your report was succesfully run! Please click the reset button if you would like to run another report.'
            }));

            setGlobalData(globalData => ({
                ...globalData,
                awardsReport: resData.awards,
                awardsLoaded: !globalData.awardsLoaded
            }));

        }


    }

    const handleSubmit1 = () => {

        setState({
            beginDate: '01/01/2021',
            finishDate: '01/02/2021'
        });

        setFieldErrors({

            firstDate: true,
            finishDate: true,
            beginMessage: '',
            endMessage: '',
            success: false,
            server: false,
            message: '',

        });

    }


    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <Navigation buttons={[
                {
                    name: 'Members',
                    link: '/admin/create'
                },
                {
                    name: 'Chapters',
                    link: '/admin/chapters'
                },
                {
                    name: 'Records',
                    link: '/admin/records'
                },
                {
                    name: 'Log Out',
                    link: '/logout'
                }
            ]} ></Navigation>
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container xs={12} md={12} spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Card elevation={5} style={{ borderRadius: 10 }}>
                                <CardHeader style={{ backgroundColor: 'rgba(227,44,44)' }} title='Report Parameters'>
                                </CardHeader>
                                <CardContent>
                                    <Grid container alignItems='center' justify='center' spacing={2} item xs={12} md={12}>

                                        <Grid item xs={6} md={6}>

                                            <KeyboardDatePicker
                                                fullWidth
                                                helperText={fieldErrors.firstDate && "Please pick a date"}
                                                id='beginDate'
                                                error={fieldErrors.firstDate}
                                                label="Begin Date (Inclusive)"
                                                format="MM/dd/yyyy"
                                                value={state.beginDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }} />

                                        </Grid>

                                        <Grid item xs={6} md={6}>

                                            <KeyboardDatePicker
                                                fullWidth
                                                id='finishDate'
                                                helperText={fieldErrors.finishDate && "Please pick a date"}
                                                label="End Date (Inclusive)"
                                                format="MM/dd/yyyy"
                                                value={state.finishDate}
                                                error={fieldErrors.finishDate}
                                                onChange={handleDateChange2}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }} />

                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider></Divider>
                                {fieldErrors.server && <p style={{ textAlign: 'center', paddingTop: '10px' }}>{fieldErrors.message}</p>}
                                {fieldErrors.success && <p style={{ textAlign: 'center', paddingTop: '10px' }}>{fieldErrors.message}</p>}
                                <CardActions style={{ justifyContent: 'center' }}>
                                    {fieldErrors.success ? <Button size='medium' onClick={handleSubmit1}>RESET</Button> : <Button size='medium' onClick={handleSubmit}>RUN REPORT</Button>}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item={8} md={8}>
                            <ReportsTable></ReportsTable>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </div>
        </MuiThemeProvider>
    )
}

export default Misc
