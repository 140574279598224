import { React, useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProvider, createMuiTheme, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import theme from '../../Theme';
import GlobalState from '../../GlobalState';

function ReportsTable() {


    const useStyles = makeStyles({
        myEditIcon: {
            color: "rgb(24,77,163)",
        },
        myTrashIcon: {
            color: 'rgb(227,44,44)'
        },
        myDownloadIcon: {
            color: '#4CBB17'
        },
        paper: {
            backgroundColor: '#222222'
        }
    });

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [globalData, setGlobalData] = useContext(GlobalState);

    const getAdvisers = async () => {


        try {


            setData(globalData.awardsReport);



        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAdvisers();
    }, [globalData.awardsLoaded]);


    return (

        <MuiThemeProvider theme={theme}>

            <MaterialTable
                style={{ backgroundColor: '#222222' }}
                components={{
                    Container: props => <Paper className={classes.paper} {...props} elevation={5} />,

                    Toolbar: props => (
                        <div style={{ backgroundColor: 'rgb(24,77,163)', color: 'rgba(255,255,255,.87)' }}>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}

                title="Member Accounts"
                icons={{
                    Edit: () => <EditIcon className={classes.myEditIcon} />,
                    Delete: () => <DeleteOutlineIcon className={classes.myTrashIcon} />,
                    Export: () => <CloudDownloadIcon className={classes.myDownloadIcon} />
                }}
                columns={[
                    { title: 'Name', field: 'name', type: "string", },
                    { title: 'Last Name', field: 'lastName', type: "string", },
                    { title: 'National ID', field: 'nationalID', type: "string", },
                    { title: 'Email', field: 'email', type: "string", },
                    { title: 'School', field: 'chapterString', type: "string", },
                    { title: 'Award', field: 'award', type: 'string', },
                    { title: 'Award Date', field: 'awardDate', type: 'date', }
                ]}
                data={data}
                options={{
                    search: true,
                    exportButton: true,
                    exportAllData: true,
                    actionsColumnIndex: -1,
                    pageSize: 5,
                    headerStyle: { backgroundColor: '#22222' },

                }}

                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Table',
                        isFreeAction: true,
                        onClick: (event) => getAdvisers(),
                        editable: false
                    }
                ]}
            />

        </MuiThemeProvider>
    )
}

export default ReportsTable
