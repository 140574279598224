import { React, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import logo from '../../images/logo.png'
import theme from '../../Theme';
import { MuiThemeProvider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import GlobalState from '../../GlobalState';



function Navigation(props) {

    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            paddingLeft: '5px',
            flexGrow: 1,
        },
    }));

    const [globalData,setGlobalData] = useContext(GlobalState)

    const handleClick = (event) => {

        const id = event.currentTarget.id;
        console.log(event.currentTarget);

        var link = '';

        props.buttons.forEach(element => {

            if (element.name === id)
                link = element.link;

        });

        console.log(link)
        history.push(link)

    }

    const makeButton = () => {

        var elements = []

        props.buttons.forEach(element => {

            elements = [...elements, <Button variant='contained' style={{ marginLeft: '15px', color: 'black' }} id={element.name} onClick={handleClick} color='inherit'>{element.name}</Button>]

        });

        return elements;

    };





    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                <AppBar position="static" style={{background: '#0A2E7F'}}>
                    <Toolbar>
                        <img src={logo} alt="logo" height="50" width="50"></img>
                        <Typography variant="h6" className={classes.title}>
                            {`Georgia FBLA CSA - ${globalData.name}`}
                        </Typography>
                        {makeButton()}
                    </Toolbar>
                </AppBar>
            </div>
        </MuiThemeProvider>

    );
}

export default Navigation
