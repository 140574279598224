import { React, useEffect, useContext, useState } from 'react';
import Navigation from '../Navbar/Navigation';
import {
    Grid,
    MuiThemeProvider,
    makeStyles,
    TextField,
    Card,
    CardContent,
    CardActions,
    Button,
    Divider,
    CircularProgress
} from '@material-ui/core';
import theme from '../../Theme';
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';

import GlobalState from '../../GlobalState';
import { CircularProgressWith } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import AdviserRecordsTable from './AdviserRecordsTable';
import StudentsTable from './StudentsTable';
import CreateMemberForm from './CreateMemberForm';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import GroupIcon from '@material-ui/icons/Group';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Skeleton from '@material-ui/lab/Skeleton';
import PasswordReset from './PasswordReset'


function CreateMembers() {

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            background: '#dad6df',
            minHeight: '90.5vh',

        }
    }));

    const [globalData, setGlobalData] = useContext(GlobalState);
    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <Navigation buttons={[
                {
                    name: 'Records',
                    link: '/advisers'
                },
                {
                    name: 'Log Out',
                    link: '/logout'
                }
            ]} />
            <div className={classes.root}>
                <Grid container spacing={2} justify='center'>
                    <Grid item md={8}>
                        <StudentsTable />
                    </Grid>
                    {!globalData.chaptersLoaded ? <CircularProgress /> :
                        <Grid container item md={4} spacing={1} justify='flex-start' alignContent='flex-start'>
                            <Grid item md={6}>
                                <Card elevation={5} style={{ height: '100%' }}>
                                    <div style={{ backgroundColor: 'rgb(24,77,163)', minWidth: '100' }}>
                                        <h2 style={{ textAlign: 'center', padding: '15px' }}>Monthly Change</h2>
                                    </div>
                                    <CardContent>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item md={2} xs={2}>
                                                <ImportExportIcon fontSize='large' />
                                            </Grid>
                                            <Grid item md={10} xs={10}>
                                                <h1 style={{ textAlign: 'center' }}>{globalData.change} %</h1>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6}>
                                <Card elevation={5} style={{ height: '100%' }}>
                                    <div style={{ backgroundColor: 'rgb(24,77,163)', minWidth: '100' }}>
                                        <h2 style={{ textAlign: 'center', padding: '15px' }}>{globalData.month} Total</h2>
                                    </div>
                                    <CardContent>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item md={2} xs={2}>
                                                <QueryBuilderIcon fontSize='medium' />
                                            </Grid>
                                            <Grid item md={10} xs={10}>
                                                <h1 style={{ textAlign: 'center' }}>{Math.floor(globalData.current / 60)}h {globalData.current % 60}m </h1>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6}>
                                <Card elevation={5} style={{ height: '100%' }}>
                                    <div style={{ backgroundColor: 'rgb(24,77,163)', minWidth: '100' }}>
                                        <h2 style={{ textAlign: 'center', padding: '15px' }}>Total Active</h2>
                                    </div>
                                    <CardContent>
                                        <Grid container direction='row' justify='center' alignItems='center'>
                                            <Grid item md={2} xs={2}>
                                                <GroupIcon fontSize='medium' />
                                            </Grid>
                                            <Grid item md={10} xs={10}>
                                                <h1 style={{ textAlign: 'center' }}>{globalData.studentCount} mbrs.</h1>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6}>
                                <Card elevation={5} style={{ height: '100%' }}>
                                    <div style={{ backgroundColor: 'rgb(24,77,163)', minWidth: '100' }}>
                                        <h2 style={{ textAlign: 'center', padding: '15px' }}>Approval Pending</h2>
                                    </div>
                                    <CardContent>
                                        <Grid container padding={2} direction='row' justify='center' alignItems='center'>
                                            <Grid item md={2} xs={2}>
                                                <HourglassEmptyIcon fontSize='medium' />
                                            </Grid>
                                            <Grid item md={10} xs={10}>
                                                <h1 style={{ textAlign: 'center' }}>{globalData.totalSubmissions} {globalData.totalSubmissions === 1 ? "record" : "records"}</h1>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={12}>

                                <CreateMemberForm />

                            </Grid>

                            <Grid item md={12}>

                                <PasswordReset />

                            </Grid>
                        </Grid>
                    } </Grid>

            </div>
        </MuiThemeProvider >
    )
}

export default CreateMembers
