import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    TextField,
    Paper,
    Switch,
    FormControlLabel,
    makeStyles,
    createMuiTheme,
    MuiThemeProvider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import { React, useState, useEffect } from 'react'
import useData from './useData';
import theme from '../../Theme'

function ChapterForm() {

    const useStyles = makeStyles({
        card: {
            backgroundColor: '#263238',

        },
        cardHeader: {
            backgroundColor: 'rgba(227,44,44)',

        },
        cardButton: {
            padding: '8px',
            position: 'relative',
            top: '4px',
            color: 'rgba(255,255,255)'

        },
        paper: {
            borderRadius: 10,
        },

        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }

    });

    const { getData } = useData();

    const [fieldErrors, updateFieldErrors] = useState({
        chapter: false,
        server: false,
        success: false,
        type: false,
        message: '',
        chapterCode: '',

    });
    const [values, setValues] = useState({
        chapter: '',
        type: null,
        chapterCode: '',

    });
    useEffect(() => {
        getData();
    }, []);

    const handleReset = (e) => {
        e.preventDefault();
        setValues({
            chapter: '',
            type: ''
        });
        updateFieldErrors({
            success: false,
            server: false
        })
    };

    const handleValueChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));

        if (!(e.target.value))
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: true
            }));
        else
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: false
            }));
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (fieldErrors.chapter) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                chapter: true,
            }));
            return 0;
        }
        else if (fieldErrors.type) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                type: true,
            }));
            return 0;
        }

        if (values.chapter === '') {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                chapter: true,
            }));
            return 0;
        }
        else if (values.type === null) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                type: true,
            }));
            return 0;
        }

        try {

            const res = await fetch('/create/chapter', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    code: values.chapterCode,
                    name: values.chapter,
                    type: values.type
                })
            });
            const data = await res.json();

            if (data.error != null) {
                var errorMessage = data.error + " This chapter was not created.";
                updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: errorMessage }));
                return 0;
            };

            updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: false, success: true, message: "Successfully saved! Click the reset button below to clear the fields and add another chapter." }));
            getData();
        }

        catch (error) {
            console.log(error);
        }

    };

    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <form>
                <Card elevation={5} className={classes.paper}>
                    <CardHeader disableTypography={true} className={classes.cardHeader}
                        title={<Typography variant='h5'>Create Chapter</Typography>} />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={values.chapter}
                                    onChange={handleValueChange}
                                    error={fieldErrors.chapter}
                                    helperText={fieldErrors.chapter && "This field cannot be empty."}
                                    fullWidth
                                    label="Exact Chapter Name"
                                    name="chapter"
                                    required
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    value={values.type}
                                    options={['Middle Level', 'High School']}
                                    fullWidth
                                    required
                                    onChange={(event, value) => setValues(values => ({ ...values, type: value }))}
                                    renderInput={(params) =>
                                        <TextField {...params} error={fieldErrors.type} helperText={fieldErrors.type && "This field cannot be empty."} label="Chapter Level" variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <TextField
                                    value={values.chapterCode}
                                    onChange={handleValueChange}
                                    helperText={fieldErrors.chapterCode && fieldErrors.chapterMessage}
                                    fullWidth
                                    error={fieldErrors.chapterCode}
                                    label="Chapter Code"
                                    name="chapterCode"
                                    required
                                    variant="outlined"
                                />


                            </Grid>

                        </Grid>
                    </CardContent>
                    <Divider />
                    {fieldErrors.server && <p className={classes.message}>{fieldErrors.message}</p>}
                    {fieldErrors.success && <p className={classes.message1}>{fieldErrors.message}</p>}
                    <CardActions style={{ justifyContent: 'center' }}>
                        {fieldErrors.success ? <Button size="medium" onClick={handleReset}>RESET</Button> :
                            <Button size="medium" onClick={handleSubmit}>SUBMIT</Button>}
                    </CardActions>
                </Card>
            </form>
        </MuiThemeProvider>
    )
}

export default ChapterForm
