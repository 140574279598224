import { React, useContext, useState } from 'react'
import {
    Grid, MuiThemeProvider, makeStyles,
    Card, CardContent, Button, Typography
} from '@material-ui/core';
import theme from '../../Theme';
import EnterRecordForm from './EnterRecordForm';
import Navigation from '../Navbar/Navigation';
import GlobalState from '../../GlobalState';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import TimelineIcon from '@material-ui/icons/Timeline';
import MemberTable from './MemberTable';
import { Line } from 'react-chartjs-2';
import Popover from '@material-ui/core/Popover';



function Member() {

    const [globalData, setGlobalData] = useContext(GlobalState)

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            background: '#dad6df',
        },
        typography: {
            padding: theme.spacing(1),
        },
        type: {
            textAlign: 'center'
        }
    }));

    const classes = useStyles();

    const data = {
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
            {
                label: 'Your Hours Earned',
                data: globalData.monthlyData,
                fill: false,
                backgroundColor: 'green',
                borderColor: 'rgba(0, 100, 0, 0.2)',
            },
            {
                label: 'Avg. Hours Earned Per Member',
                data: globalData.allMonthlyData,
                fill: false,
                backgroundColor: 'rgb(24,77,163)',
                borderColor: 'rgba(24,77,163,.3)',
            },

        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'New Approved Hours',
                color: 'white',
                font: {
                    size: '20vh',
                    weight: 'normal',
                }
            }
        }
    };


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const placeholder = 'N/A'

    return (
        <MuiThemeProvider theme={theme}>
            <Navigation buttons={[
                {
                    name: 'Logout',
                    link: '/logout'
                },
            ]} ></Navigation>
            <div className={classes.root}>

                <Grid container spacing={2} justify='space-evenly' alignContent='center' direction='row'>
                    <Grid container item md={6} spacing={1} justify='center' alignContent='center'>
                        <Grid container item md={12} spacing={0} justify='center' alignContent='center'>

                            <Grid container item md={12} spacing={1}>
                                <Grid item md={4} >
                                    <Card elevation={5} style={{ height: '100%' }}>
                                        <div style={{ backgroundColor: 'rgb(24,77,163)', minWidth: '100' }}>
                                            <h2 style={{ textAlign: 'center', padding: '15px' }}>Current Progress</h2>
                                        </div>
                                        <CardContent>
                                            <Grid container direction='column' justify='center' alignItems='center'>
                                                <div style={{ paddingTop: '15px', width: '75%' }}>
                                                    <CircularProgressbarWithChildren
                                                        value={globalData.percentage}
                                                        strokeWidth={8}
                                                        circleRatio={.75}
                                                        styles={buildStyles({
                                                            rotation: 1 / 2 + 1 / 8,
                                                            strokeLinecap: "butt",
                                                            pathColor: "green",
                                                            trailColor: "#eee",
                                                        })}>
                                                        <h1>{`${globalData.percentage}%`}</h1>
                                                        <TimelineIcon fontSize='large' />
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                                <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                                                    {globalData.level}
                                                </Button>

                                                {globalData.dataLoaded ?

                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Typography className={classes.typography}>Service Award: {globalData.summary.first === '' ? placeholder : globalData.summary.first} </Typography>
                                                        <Typography className={classes.typography}>1 Star Award: {globalData.summary.second === '' ? placeholder : globalData.summary.second} </Typography>
                                                        <Typography className={classes.typography}>2 Star Award: {globalData.summary.third === '' ? placeholder : globalData.summary.third} </Typography>
                                                        <Typography className={classes.typography}>3 Star Award: {globalData.summary.fourth === '' ? placeholder : globalData.summary.fourth} </Typography>
                                                        <Typography className={classes.typography}>4 Star Award: {globalData.summary.fifth === '' ? placeholder : globalData.summary.fifth} </Typography>
                                                        <Typography className={classes.typography}>5 Star Award: {globalData.summary.sixth === '' ? placeholder : globalData.summary.sixth} </Typography>
                                                    </Popover>

                                                    : <></>}

                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <Card style={{ height: '100%' }} elevation={5}>
                                        <div style={{ backgroundColor: 'green', minWidth: '100' }}>
                                            <h2 style={{ textAlign: 'center', padding: '15px' }}>Total Approved</h2>
                                        </div>
                                        <CardContent>
                                            <Grid container spacing={1} direction='column' justify='center' alignItems='center'>
                                                <Grid item>
                                                    <h1 style={{ paddingTop: '10px', fontSize: '3rem', textAlign: 'center' }}> {Math.floor(globalData.approvedTime / 60)} </h1>
                                                </Grid>
                                                <Grid item>
                                                    <h3 style={{ textAlign: 'center' }}>Hours</h3>
                                                </Grid>
                                                <Grid item>
                                                    <h1 style={{ paddingTop: '5px', fontSize: '3rem', textAlign: 'center' }}> {globalData.approvedTime % 60} </h1>
                                                </Grid>
                                                <Grid item >
                                                    <h3 style={{ textAlign: 'center' }}>Minutes</h3>
                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item md={4} xs={6}>
                                    <Card style={{ height: '100%' }} elevation={5}>
                                        <div style={{ backgroundColor: 'rgb(227,44,44)', minWidth: '100' }}>
                                            <h2 style={{ textAlign: 'center', padding: '15px' }}>Total Unapproved</h2>
                                        </div>
                                        <CardContent>
                                            <Grid container spacing={1} direction='column' justify='center' alignItems='center'>
                                                <Grid item>
                                                    <h1 style={{ paddingTop: '10px', fontSize: '3rem', textAlign: 'center' }}> {Math.floor(globalData.unApprovedTime / 60)} </h1>
                                                </Grid>
                                                <Grid item>
                                                    <h3 style={{ textAlign: 'center' }}>Hours</h3>
                                                </Grid>
                                                <Grid item>
                                                    <h1 style={{ paddingTop: '5px', fontSize: '3rem', textAlign: 'center' }}> {globalData.unApprovedTime % 60} </h1>
                                                </Grid>
                                                <Grid item >
                                                    <h3 style={{ textAlign: 'center' }}>Minutes</h3>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item md={12} xs={12}>
                            <MemberTable />
                        </Grid>
                    </Grid>
                    <Grid container item md={6} spacing={2} justify='center' alignContent='flex-start' direction='row'>
                        <Grid item md={12}>
                            <Card elevation={5}>
                                <div style={{ padding: '15px' }}>
                                    <h3 style={{ fontWeight: 'bolder' }}>Welcome, {globalData.name}. Hope today is a great day for you!</h3>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item md={12}>
                            <EnterRecordForm />
                        </Grid>
                        <Grid item md={12}>
                            <Card elevation={5} style={{ height: '100%' }}  >
                                <div style={{ padding: '15px' }}>
                                    <Line data={data} options={options} />
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
        </MuiThemeProvider>
    )
}

export default Member
