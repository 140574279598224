import { useState, useContext } from 'react';
import GlobalState from '../../GlobalState';

const useData = () => {

    const [globalData, updateGlobalData] = useContext(GlobalState);

    const getData = async () => {
        var currentData = []
        var currentData1 = []
        try {
            const res = await fetch('/data/chapters')
            const resData = await res.json();
            const array = resData.chapters;
            var counter = 0;
            array.forEach(item => {
                const newItem = {
                    label: item.name,
                    id: item._id
                }
                currentData = [...currentData, newItem];
            });
            const res1 = await fetch('/data/advisers');
            const resData1 = await res1.json();
            const array1 = resData1.advisers;
            array1.forEach(item => {
                var chapterIDs = item.chapterString.join();
                const newEntry = {
                    name: item.name,
                    chapterIds: item.chapters,
                    email: item.email,
                    school: chapterIDs,
                    activeSince: item.dateCreated,
                    activeTo: item.dateUncreated,
                    isActive: item.isActive
                };
                currentData1 = [...currentData1, newEntry];
            });
            
            updateGlobalData(globalData => ({ ...globalData, chapters: currentData, advisers:currentData1 }));
        }

        catch (error) {
                console.log(error);
            }

        }

    return { getData };
    }

    export default useData;