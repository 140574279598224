import React, { useContext, useState, useEffect } from 'react';
import GlobalState from '../../GlobalState';
import {
    CardHeader,
    Grid, MuiThemeProvider, Card, Typography, Divider, CardContent, makeStyles,
    TextField, CardActions, Button
} from '@material-ui/core';
import theme from '../../Theme';
import Autocomplete from '@material-ui/lab/Autocomplete';



function AdminPasswordReset() {

    const [globalData, setGlobalData] = useContext(GlobalState);

    const [values, setValues] = useState({

        email: '',
        password: ''

    });

    const [errors, setErrors] = useState({

        password: false,
        email: false,
        firstField: false,
        success: false,
        server: false

    })


    const [data, setData] = useState([]);



    const getAdvisers = async () => {
        var currentData = [];
        console.log(globalData)
        try {
            const res = await fetch('/data/everyone', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const resData = await res.json();

            console.log(resData)

            if (resData.advisers) {
                setData(data => ({

                    ...data,
                    pLoad: true, 
                    students: resData.advisers

                }))
            };



        }
        catch (error) {
            console.log(error);
        }
    };





    useEffect(() => {

        getAdvisers();


    }, []);


    const useStyles = makeStyles({
        card: {
            backgroundColor: '#263238',

        },
        cardHeader: {
            backgroundColor: 'rgb(24,77,163)',

        },
        cardButton: {
            padding: '8px',
            position: 'relative',
            top: '4px',
            color: 'rgba(255,255,255)'

        },
        paper: {
            borderRadius: 10,
        },

        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }

    });

    const handleReset = () => {




    }


    const handleSubmit = async (e) => {


        if (!errors.firstField)
            return 0;

        if (errors.email || errors.password) {


            setErrors(errors => ({
                ...errors,
                password: true,
            }));

            return 0;
        }




        try {

            const res = await fetch('/auth/changePassword', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    email: values.email.email,
                    newPassword: values.password
                })
            });
            const data = await res.json();

            if (data.error != null) {
                var errorMessage = "This chapter was not created due to an error, contact your admin.";
                setErrors(errors => ({ ...errors, server: true, message: errorMessage }));
                return 0;
            };

            setErrors(errors => ({ ...errors, server: false, success: true, message: "Successfully saved! Click the reset button below to clear the fields and reset another password" }));

        }

        catch (error) {
            console.log(error);
        }


    };


    const classes = useStyles();

    const handleValueChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));

        if (!(e.target.value))
            setErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: true
            }));
        else
            setErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: false
            }));
    }


    const autoChange = (event, value) => {

        if (value) {
            setValues(values => ({
                ...values,
                email: value

            }));

            setErrors(errors => ({ ...errors, firstField: true }))

        }


    }


    return (

        <MuiThemeProvider theme={theme}>

            <Card elevation={5} className={classes.paper}>
                <CardHeader disableTypography={true} className={classes.cardHeader}
                    title={<Typography variant='h5'>Password Change</Typography>} />
                <Divider />

                <CardContent>

                    <Grid container spacing={2} >

                        {data.pLoad &&

                            <Grid item xs={12} md={12}>

                                <Autocomplete
                                    fullWidth
                                    value={values.email}
                                    options={data.students}
                                    getOptionLabel={(option) => option.email}
                                    onChange={autoChange}
                                    renderInput={(params) => <TextField {...params} error={errors.email}
                                        helperText={errors.email && "This field cannot be empty."} name="email" label="Choose Email" variant="outlined" />}
                                />

                            </Grid>

                        }

                        {errors.firstField &&

                            <Grid item xs={12} md={12}>

                                <TextField
                                    value={values.password}
                                    onChange={handleValueChange}
                                    error={errors.password}
                                    helperText={errors.password && "This field cannot be empty."}
                                    fullWidth
                                    label="New Password"
                                    name="password"
                                    required
                                    variant="outlined"
                                    fullWidth
                                />

                            </Grid>
                        }

                    </Grid>

                </CardContent>
                <Divider />
                {errors.server && <p className={classes.message}>{errors.message}</p>}
                {errors.success && <p className={classes.message1}>{errors.message}</p>}
                <CardActions style={{ justifyContent: 'center' }}>
                    {errors.success ? <Button size="medium" onClick={handleReset}>RESET</Button> :
                        <Button size="medium" onClick={handleSubmit}>SUBMIT</Button>}
                </CardActions>

            </Card>


        </MuiThemeProvider>
    )
}

export default AdminPasswordReset
