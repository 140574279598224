import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    TextField,
    Paper,
    Switch,
    FormControlLabel,
    makeStyles,
    createMuiTheme,
    MuiThemeProvider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import { React, useState, useEffect, useContext } from 'react';
import theme from '../../Theme';
import GlobalState from '../../GlobalState';

function AdminMemberForm() {
    const useStyles = makeStyles({
        card: {
            backgroundColor: '#263238',

        },
        cardHeader: {
            backgroundColor: 'rgb(24,77,163)',

        },
        cardButton: {
            padding: '8px',
            position: 'relative',
            top: '4px',
            color: 'rgba(255,255,255)'

        },
        paper: {
            borderRadius: 10,
        },

        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }

    });


    const [globalData, updateGlobalData] = useContext(GlobalState);


    const [fieldErrors, updateFieldErrors] = useState({
        name: false,
        email: false,
        server: false,
        success: false,
        message: '',
        national: false,
        chapter: false,
        lastName: false,
    });
    const [values, setValues] = useState({
        name: '',
        email: '',
        national: '',
        chapter: '',
        lastName: ''
    });

    useEffect(() => {
        console.log(globalData)
    }, []);

    const handleReset = (e) => {
        e.preventDefault();
        setValues({

            name: '',
            email: '',
            national: '',
            lastName: ''

        });
        updateFieldErrors({
            success: false,
            server: false,
            name: false,
            email: false,
            national: false,
            chapter: false,
            lastName: false,
        });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();


        if ((fieldErrors.email || fieldErrors.name || fieldErrors.chapter || fieldErrors.national || fieldErrors.lastName)) {
            updateFieldErrors({
                email: true,
                name: true,
                chapter: true,
                national: true
            });

            return 0;

        }
        if ((values.email === "" || values.name === "" || values.national === "" || values.chapter == null || values.lastName == "")) {
            updateFieldErrors({
                email: true,
                name: true
            });

            return 0;

        }



        try {
            const userRes = await fetch('/create/student', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: values.name,
                    lastName: values.lastName,
                    email: values.email,
                    password: Math.random().toString(36).slice(2),
                    chapter: values.chapter.id,
                    national: values.national

                })
            });

            const data = await userRes.json();

            if (data.error != null) {
                updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: data.error }));
                return 0;
            }
            else {

                updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: false, success: true, message: "Successfully saved! Click the reset button below to clear the fields and add another student." }));
            }

        }
        catch (e) {
            console.log(e);
        }

    };

    const handleValueChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));

        if (!(e.target.value))
            updateFieldErrors(values => ({
                ...fieldErrors,
                [e.target.name]: true
            }));
        else
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: false
            }));


    };


    const onTagsChange = (event, value) => {
        setValues(values => ({ ...values, chapter: value }));
        if (value == null)
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, chapter: true }));
        else
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, chapter: false }));
    };


    const classes = useStyles();
    return (
        <MuiThemeProvider theme={theme}>
            <form>
                <Card elevation={5} className={classes.paper}>
                    <CardHeader disableTypography={true} className={classes.cardHeader}
                        title={<Typography variant='h5'>Create Student Account</Typography>} />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} justify="center">
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    error={fieldErrors.name}
                                    helperText={fieldErrors.name && "This field cannot be empty."}
                                    onChange={handleValueChange}
                                    value={values.name}
                                    label="First Name"
                                    name="name"
                                    required variant="outlined" />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    error={fieldErrors.lastName}
                                    helperText={fieldErrors.lastName && "This field cannot be empty."}
                                    onChange={handleValueChange}
                                    value={values.lastName}
                                    label="Last Name"
                                    name="lastName"
                                    required variant="outlined" />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    value={values.email}
                                    error={fieldErrors.email}
                                    helperText={fieldErrors.email && "This field cannot be empty."}
                                    onChange={handleValueChange}
                                    label="Email"
                                    name="email"
                                    required
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    value={values.national}
                                    error={fieldErrors.national}
                                    helperText={fieldErrors.email && "This field cannot be empty."}
                                    onChange={handleValueChange}
                                    label="Member Record Number"
                                    name="national"
                                    required
                                    variant="outlined" />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                {!globalData.chaptersLoaded ? <Skeleton variant="rect" width={150} height={40} /> :
                                    <Autocomplete
                                        fullWidth
                                        value={values.chapter}
                                        onChange={onTagsChange}
                                        options={globalData.chapterData}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} error={fieldErrors.chapter}
                                            helperText={fieldErrors.chapter && "This field cannot be empty."} name="chapter" label="Choose Chapter" variant="outlined" />}
                                    />}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    {fieldErrors.server && <p className={classes.message}>{fieldErrors.message}</p>}
                    {fieldErrors.success && <p className={classes.message1}>{fieldErrors.message}</p>}
                    <CardActions style={{ justifyContent: 'center' }}>
                        {fieldErrors.success ? <Button size="medium" onClick={handleReset}>RESET</Button> :
                            <Button size="medium" onClick={handleSubmit}>SUBMIT</Button>}
                    </CardActions>
                </Card>
            </form>
        </MuiThemeProvider>
    )
}

export default AdminMemberForm
