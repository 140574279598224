import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    TextField,
    Paper,
    Switch,
    FormControlLabel,
    makeStyles,
    createMuiTheme,
    MuiThemeProvider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import { React, useState, useEffect, useContext } from 'react'
import useData from './useData';
import theme from '../../Theme'
import GlobalState from '../../GlobalState';

function EditAdviserForm() {

    const useStyles = makeStyles({
        card: {
            backgroundColor: '#263238',

        },
        cardHeader: {
            backgroundColor: 'green',

        },
        cardButton: {
            padding: '8px',
            position: 'relative',
            top: '4px',
            color: 'rgba(255,255,255)'

        },
        paper: {
            borderRadius: 10,
        },

        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }

    });

    const { getData } = useData();

    const classes = useStyles();

    const [globalData, updateGlobalData] = useContext(GlobalState);

    useEffect(() => {
        getData();
    }, [])

    const [values, setValues] = useState({
        fieldValue: '',
        name: '',
        nameEntered: false,
        emailOptions: [],
        emailEntered: false,
        emailPicked: '',
        chapterOptions: [],
        chapterPicked: [],
        chapterEntered: false,
        adviserName: 'The adviser picked'
    });

    const [fieldErrors, setFieldErrors] = useState({
        name: false,
        email: false,
        chapter: false,
        server: false,
        success: false,
        message: ''

    });


    const handleReset = (e) => {
        e.preventDefault();
        setValues({
            fieldValue: ''
        });
        setFieldErrors({
            success: false,
            server: false
        });
    };


    const handleOnChange1 = (event, value) => {
        if (value) {
            var chapters = ''
            var name = ''
            globalData.advisers.forEach(element => {
                if (element.email.normalize() === value.email.normalize())
                    chapters = element.school;
                name = element.name
            });
            setValues(values => ({ ...values, emailEntered: true, emailPicked: value, chapterOptions: chapters, adviserName: name }));
            setFieldErrors({ email: false });
        }
        else {
            setValues(values => ({ ...values, emailEntered: false, emailPicked: '', chapterOptions: [], adviserName: 'The adviser picked' }));
            setFieldErrors({ email: true });
        }

    };

    const handleOnChange2 = (event, value) => {

        if (value.length != 0) {
            setValues(values => ({ ...values, chapterEntered: true, chapterPicked: value }));
            setFieldErrors({ chapter: false });
        }
        else {
            setValues(values => ({ ...values, chapterEntered: true, chapterPicked: [] }));
            setFieldErrors({ chapter: true });
        }

    };

    const handleFormSubmit = async (e) => {


        if (!(values.chapterEntered && values.emailEntered && values.nameEntered)) {
            setFieldErrors({
                name: true,
                email: true,
                chapter: true,
            });
            return 0;
        };

        try {

            const res = await fetch('/data/updateAdviserChapter', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: values.emailPicked,
                    chapter: values.chapterPicked,
                })
            });

            const data = await res.json();

            if (data.error != null) {

                setFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: data.error }));
                return 0;

            };

            setFieldErrors(fieldErrors => ({ ...fieldErrors, server: false, success: true, message: "Successfully saved! Click the reset button below to clear the fields and edit another adviser" }));


        }

        catch (error) {

            console.log(error);

        };


    };


    return (
        <MuiThemeProvider theme={theme}>
            <form>
                <Card elevation={5} className={classes.paper}>
                    <CardHeader disableTypography={true} className={classes.cardHeader}
                        title={<Typography variant='h5'>Change Chapter Assignments</Typography>} />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} justify="center">

                            <Grid item xs={12}>
                                <Autocomplete
                                    value={values.emailPicked}
                                    onChange={handleOnChange1}
                                    options={globalData.advisers}
                                    getOptionLabel={(option) => option.email}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            error={fieldErrors.email}
                                            helperText={fieldErrors.email && "This field cannot be empty."}
                                            label="Adviser Email" variant="outlined" />}
                                />
                            </Grid>
                            {values.emailPicked ?
                                <Grid item xs={12}>
                                    <Autocomplete

                                        value={values.chapterPicked}
                                        multiple
                                        onChange={handleOnChange2}
                                        options={globalData.chapters}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) =>
                                            <TextField
                                                error={fieldErrors.chapter}
                                                helperText={(fieldErrors.chapter ? "This field cannot be empty. " : '') + values.adviserName + " was registered for these chapters before: " + values.chapterOptions}
                                                {...params} label="Assign Chapters" variant="outlined" />}
                                    />
                                </Grid> : <div></div>}
                        </Grid>
                    </CardContent>
                    <Divider />
                    {fieldErrors.server && <p className={classes.message}>{fieldErrors.message}</p>}
                    {fieldErrors.success && <p className={classes.message1}>{fieldErrors.message}</p>}
                    <CardActions style={{ justifyContent: 'center' }}>
                        {fieldErrors.success ? <Button size="medium" onClick={handleReset}>RESET</Button> :
                            <Button size="medium" onClick={handleFormSubmit}>SUBMIT</Button>}
                    </CardActions>
                </Card>
            </form>
        </MuiThemeProvider>
    )
}

export default EditAdviserForm
