import { React, useState, useContext } from 'react'
import theme from './Theme';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    TextField,
    Paper,
    Switch,
    FormControlLabel,
    makeStyles,
    createMuiTheme,
    MuiThemeProvider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GlobalState from './GlobalState';
import { minWidth } from '@material-ui/system';
import { LinearProgress } from '@material-ui/core';

function Password() {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            background: '#dad6df',
            minHeight: '100vh',
            minWidth: '100vw'
        },
        paper: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: 'rgb(24,77,163)',

        },
        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }
    }));


    const [fieldErrors, updateFieldErrors] = useState({
        email: false,
        server: false,
        success: false,
        type: false,
        message: '',
        emailSuccess: false,
        verification: false,
        tempMessage: 'Verification code you recieved in your email.',
        firstPassword: false,
        secondPassword: false,
        firstMessage: 'Enter your new password in this field.',
        secondMessage: 'Re-enter your password here.',
        loading: false
    });

    const [values, setValues] = useState({
        email: '',
        verification: '',
        firstPassword: '',
        secondPassword: ''

    })


    const handleValueChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));

        if (!(e.target.value))
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: true
            }));
        else
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                [e.target.name]: false
            }));
    }

    const classes = useStyles();

    const handleSubmit = async (e) => {


        if (fieldErrors.email)
            return 0;
        if (values.email === '') {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                email: true

            }));
            return 0;
        }

        updateFieldErrors(fieldErrors => ({
            ...fieldErrors,
            loading: true
        }));

        const res = await fetch('/auth/resetEmail', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: values.email
            })
        });

        const resData = await res.json();

        if (resData.error != null) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                server: true,
                message: resData.error,
                loading: false
            }));
        }

        else {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                success: true,
                message: 'Success! Please enter the verification code and your new password in the fields above.',
                emailSuccess: true,
                loading: false,
                server: false,
                final: true
            }));

        }


    }

    const handlePassword = async (e) => {

        if (fieldErrors.firstPassword || fieldErrors.secondPassword || fieldErrors.verification || fieldErrors.email)
            return 0;

        if (values.verification === '') {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                tempMessage: 'This field cannot be empty, enter the verification code from the email you received.',
                verification: true
            }));
            return 0;
        }
        if (values.firstPassword === '') {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                firstMessage: 'This field cannot be empty, enter your new password.',
                firstPassword: true
            }));
            return 0;
        }
        if (values.secondPassword === '') {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                secondMessage: 'This field cannot be empty, enter your new password.',
                secondPassword: true
            }));
            return 0;
        }

        if (values.firstPassword != values.secondPassword) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                success: false,
                server: true,
                message: 'The passwords do not match, please ensure they are the same'
            }));
            return 0;
        }

        if (values.firstPassword.length < 8 || values.firstPassword.length > 60) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                success: false,
                server: true,
                message: 'Please ensure the password is longer than 8 characters and shorter than 60 characters.'
            }));
            return 0;
        }


        updateFieldErrors(fieldErrors => ({
            ...fieldErrors,
            loading: true
        }));


        const res = await fetch('/auth/resetPassword', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: values.email,
                tempPassword: values.verification,
                newPassword: values.firstPassword,
            })
        });

        const resData = await res.json();

        if (resData.error != null) {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                server: true,
                message: resData.error,
                loading: false,
                success: false
            }));
        }

        else {
            updateFieldErrors(fieldErrors => ({
                ...fieldErrors,
                success: true,
                message: 'Done! Your password has been succesfully changed.',
                emailSuccess: true,
                loading: false,
                server: false,
                final: false
            }));

        }



    };

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <Card elevation={5}>
                            <CardContent>
                                <Grid container spacing={2} justify='center' alignContent='center'>
                                    <Grid item xs={12} md={12}>
                                        <h2 style={{ padding: '5px' }}>GA FBLA CSA Password Reset</h2>
                                        <p>You will be sent an email with instructions.</p>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {!fieldErrors.emailSuccess &&
                                            <TextField
                                                onChange={handleValueChange}
                                                fullWidth
                                                variant='outlined'
                                                required
                                                label="Email"
                                                name='email'
                                                value={values.email}
                                                error={fieldErrors.email}
                                                helperText={fieldErrors.email && fieldErrors.message}
                                            />}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {fieldErrors.emailSuccess &&
                                            <TextField
                                                onChange={handleValueChange}
                                                fullWidth
                                                variant='outlined'
                                                required
                                                label="Verification Code"
                                                name='verification'
                                                value={values.verification}
                                                error={fieldErrors.verification}
                                                helperText={fieldErrors.tempMessage}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {fieldErrors.emailSuccess &&
                                            <TextField
                                                onChange={handleValueChange}
                                                fullWidth
                                                variant='outlined'
                                                required
                                                label="New Password"
                                                name='firstPassword'
                                                value={values.firstPassword}
                                                error={fieldErrors.firstPassword}
                                                helperText={fieldErrors.firstMessage}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {fieldErrors.emailSuccess &&
                                            <TextField
                                                onChange={handleValueChange}
                                                fullWidth
                                                variant='outlined'
                                                required
                                                label="Repeat Password"
                                                name='secondPassword'
                                                value={values.secondPassword}
                                                error={fieldErrors.secondPassword}
                                                helperText={fieldErrors.secondMessage}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            {fieldErrors.server && <p style={{ textAlign: 'center', paddingTop: '5px', paddingLeft: '2.5px', paddingRight: '2.5px', color: '#ff7961' }}>{fieldErrors.message}</p>}
                            {fieldErrors.success && <p style={{ textAlign: 'center', paddingTop: '5px', paddingLeft: '2.5px', paddingRight: '2.5px', color: '#66FF00' }}>{fieldErrors.message}</p>}
                            {fieldErrors.loading && <LinearProgress color='secondary' />}
                            <CardActions style={{ justifyContent: 'center' }}>
                                {!fieldErrors.emailSuccess && <Button size='medium' onClick={handleSubmit} >SUBMIT EMAIL</Button>}
                                {fieldErrors.final && <Button size='medium' onClick={handlePassword}>SUBMIT PASSWORDS </Button>}
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>

        </MuiThemeProvider>
    )
}

export default Password
