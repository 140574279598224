import {
    Button,
    Card, CardActions, CardContent,
    CardHeader, Divider,
    Grid, makeStyles, MuiThemeProvider, TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { React, useContext, useState } from 'react';
import GlobalState from '../../GlobalState';
import theme from '../../Theme';

function EnterRecordForm() {

    const [userData, updateUserData] = useContext(GlobalState);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            background: '#dad6df',
            minHeight: '90.5vh'
        },
        paper: {
            borderRadius: 10,
        },
        cardHeader: {
            backgroundColor: 'rgb(24,77,163)',

        },
        message: {
            paddingTop: '10px',
            textAlign: 'center',
            color: '#f44336'
        },
        message1: {
            paddingTop: '10px',
            textAlign: 'center',
            color: 'green'
        }
    }));

    const [fieldErrors, updateFieldErrors] = useState({
        description: false,
        server: false,
        success: false,
        benefactor: false,

    });

    const classes = useStyles();

    const hourIncrements = ['0', '1', '2', '3', '4', '5',
        '6', '7', '8', '9', '10', '11', '12', '13', '14',
        '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
        '25', '26', '27', '28', '29', '30', '31', '32', '33', '34',
        '35', '36', '37', '38', '39', '40', '41', '42', '43', '44',
    ]

    const minuteIncrements = ['0', '6', '12', '18', '24', '30', '36', '42', '48', '54']

    const [values, setValues] = useState({

        hours: '',
        minutes: '',
        description: '',
        benefactor: ''


    });

    const handleChange = (e, value) => {
        setValues(values => ({
            ...values,
            hours: value
        }));
    };

    const handleChange1 = (e, value) => {
        setValues(values => ({
            ...values,
            minutes: value
        }));
    };

    const handleFieldChanges = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));
        if (!e.target.value)
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, [e.target.name]: true }));
        else
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, [e.target.name]: false }));
    }


    const handleReset = (e) => {
        e.preventDefault();
        setValues({
            hours: '',
            minutes: '',
            description: '',
            benefactor: ''
        });
        updateFieldErrors({ success: false, server: false })

    };


    const handleSubmit = async (e) => {


        if (values.minutes === '' || values.minutes === null)
            await setValues(values => ({ ...values, minutes: '0' }));
        if (values.hours === '' || values.hours === null)
            await setValues(values => ({ ...values, hours: '0' }));
        if (values.description === '' || values.benefactor === '') {
            updateFieldErrors(fieldErrors => ({ ...fieldErrors, description: true, benefactor: true }))
            return 0;
        }

        try {

            var minutes = parseInt(values.minutes, 10);
            var hours = parseInt(values.hours, 10);

            if (isNaN(minutes))
                minutes = 0;
            if (isNaN(hours))
                hours = 0;

            console.log(minutes)
            console.log(hours)

            if (minutes === 0 && hours === 0) {
                updateFieldErrors({ server: true, message: 'At least one of the fields, hours or minutes must be filled.' })
                return 0;
            }

            const res = await fetch('/data/addRecord', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    benefactor: values.benefactor,
                    id: userData.userID,
                    time: (hours * 60 + minutes),
                    description: values.description,
                    name: userData.name,
                    lastName: userData.lastName,
                    email: userData.email,
                    chapterString: userData.chapterString

                })
            });

            const data = await res.json();

            if (data.error != null) {
                updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: true, message: 'INTERNAL ERROR: Please try again' }));
                return 0;
            }

            updateFieldErrors(fieldErrors => ({ ...fieldErrors, server: false, success: true, message: ('Succesfully created a record with ' + values.hours + " hours and " + values.minutes + " minutes.") }));

            e.preventDefault();
            setValues({
                hours: '',
                minutes: '',
                description: '',
                benefactor: ''
            });

        }
        catch (error) {
            console.log(error)
        }

    };




    return (
        <MuiThemeProvider theme={theme}>
            <Card elevation={5} className={classes.paper}>
                <CardHeader className={classes.cardHeader} title="Enter New Service Record" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3} justify='center'>
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                error={fieldErrors.benefactor}
                                helperText={fieldErrors.benefactor && "This field cannot be empty."}
                                onChange={handleFieldChanges}
                                value={values.benefactor}
                                variant='outlined'
                                label="Benefactor" name='benefactor' />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                onChange={handleChange}
                                value={values.hours}
                                options={hourIncrements}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Hours" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>

                            <Autocomplete
                                onChange={handleChange1}
                                value={values.minutes}
                                options={minuteIncrements}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Minutes" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                error={fieldErrors.description}
                                helperText={fieldErrors.description && "This field cannot be empty."}
                                onChange={handleFieldChanges}
                                value={values.description}
                                variant='outlined'
                                rows={2}
                                label="Description" name='description' />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                {fieldErrors.server && <p className={classes.message}>{fieldErrors.message}</p>}
                {fieldErrors.success && <p className={classes.message1}>{fieldErrors.message}</p>}
                <CardActions style={{ justifyContent: 'center' }}>
                    <Button size="medium" onClick={handleSubmit}>SUBMIT</Button>
                </CardActions>
            </Card>
        </MuiThemeProvider>
    )
}

export default EnterRecordForm

